<template class="pa-0">
 <v-card>
    <v-card-title>
       <span> Ingreso combustible</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

         <v-flex md2 lg2 mx-4 pt-6>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>


          <v-spacer></v-spacer>

        
             <vue-excel-xlsx  class="mx-1" v-show="data_excel.length >0"
                :data="data_excel"
                :columns="column_excel"
                :filename="'Historial_ingreso'"
                :sheetname="'hoja1'">
                <v-btn color="info">
                    <v-icon dark>get_app</v-icon>
                </v-btn>
            
              </vue-excel-xlsx>

         <v-flex>
                <v-btn dark color="success" @click="dialogInicio=true">
                  <v-icon>date_range</v-icon>
                </v-btn>
          </v-flex>

            

      <v-text-field
       class="mr-4"
        v-model="search"
        append-icon="search"
        label="buscar"
        single-line
        hide-details
      ></v-text-field>


    

            
      <v-btn color="blue" dark v-if="status_btn_add" class="mx-1" @click="dialogFile=true"><v-icon>publish</v-icon><v-icon>local_gas_station</v-icon></v-btn>



    </v-card-title>



  <v-data-table
    :headers="headers"
    :items="ArrayIngresos"
    :search="search"
    sort-by="fecha"
    class="elevation-1 pa-0"
  >
 
      
    <template v-slot:[`item.actions`]="{ item }">

      <v-icon small @click="deleteItem(item)">delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>

     <template v-slot:[`item.fecha`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha)}} </span>
     </template>


     <template v-slot:[`item.cantidad`]="{ item }">
          <mdicon name="fuel" /><span>{{ item.cantidad}} Gln</span>
     </template>

     <template v-slot:[`item.costo`]="{ item }">
          <mdicon name="cash"  style="color: red;background-color:red;" /><span>{{ item.costo}}</span>
     </template>

     <template v-slot:[`body.append`]>
        <tr>
          <td>
            <span><!--mdicon name="fuel" /--><strong>  Total Gln</strong> </span>
             <v-chip  class="ma-2" color="pink"  text-color="white">{{ total_gl}}
            </v-chip>
          </td>

          <td>
             <span><!--mdicon name="cash" /--><strong> totales $</strong></span>
            <v-chip  class="ma-2" color="green"  text-color="white">{{ total_dls }}
            </v-chip>

          </td>
        </tr>
      </template>


  </v-data-table>


       <v-dialog v-model="dialog" max-width="550px">
        
          <v-card>
         
        <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>

            </v-toolbar>



            <v-card-text>
              <v-container>

                 <v-row>

                <v-col cols="12" sm="6" md="6">
                        <v-select
                            v-model="editedItem.id_comb_vehiculo"
                            :items="UnidadesArray"
                            prepend-icon="time_to_leave"
                            label="Vehiculo"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                          
                        ></v-select>
                  </v-col>



               
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                    prepend-icon="local_gas_station"
                      v-model="editedItem.cantidad"
                      label="Cantidad Gln"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="4"  md="4">
                    <v-text-field
                      prepend-icon="payments"
                      v-model="editedItem.costo"
                      label="Costo"
                    ></v-text-field>
                  </v-col>


                   <v-col cols="12" sm="4" md="4">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.fecha"
                            label="Fecha puesto comb"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.fecha"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                  <v-col cols="12" sm="4" md="4">
                   <v-text-field
                    prepend-icon="local_gas_station"
                      v-model="editedItem.estacion"
                      label="Estacion"
                    ></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      prepend-icon="mode_comment"
                      v-model="editedItem.comentario"
                      label="Comentario"
                    ></v-text-field>
                  </v-col>



                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="save">
                Guardar
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">seguro deseas borrar ingreso?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogInicio" persistent max-width="280">
          <v-card>
            <v-toolbar flat color="fondo1" class="text-center">
            <v-toolbar-title class="white--text  text-center"><strong>Historial por fechas</strong></v-toolbar-title>
          </v-toolbar>

            <v-card-text>

              <v-flex>

                        <v-menu
                          ref="menuFecha1"
                          v-model="menuFecha1"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                      
                          offset-y
                          min-width="290px"
                        >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="fecha_inicio"
                                  label="Fecha inicio"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="fecha_inicio" locale="es"
                              no-title  @input="menuFecha1 = false" scrollable>
                              </v-date-picker>
                        </v-menu>
                  </v-flex>

              <v-flex>
                    <v-menu
                      ref="menuFecha2"
                      v-model="menuFecha2"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_fin"
                              label="Fecha fin"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_fin" locale="es"
                          no-title  @input="menuFecha2 = false" scrollable>
                          </v-date-picker>
                    </v-menu>

                  </v-flex>

              
                    <v-flex justify-center d-flex>
                      
                          <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                        <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

                  </v-flex>
      
            </v-card-text>
          
          </v-card>
      </v-dialog>



       <v-dialog  v-model="dialogFile" persistent  max-width="500px">
        <v-card>
        
                <v-toolbar dark color="fondo1">
              <v-toolbar-title>
                 <span class="headline">Importar Cargas de combustible</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialogFile=false" dark>close</v-icon>
            </v-toolbar>

            
            <v-card-text>
                    <v-row>

                       <v-flex md4 lg4 mx-4 pt-6>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                       </v-flex>

                        <v-col cols="10">
                          <v-file-input  single-line  show-size label=".xslx"  ref="file" v-model="file"  width="90px" @change="status_procesado=false; finalizadaCarga=false"></v-file-input>
                      </v-col>


                      <v-card max-width="500px" v-show="tipoFormatoCargar=='Nectar'">
                        <v-card-subtitle><v-icon color="red">info</v-icon>Importante:</v-card-subtitle>

                        <ul class="mx-4 mb-2">

                           <li>
                            <span>Los datos seran cargados y procesados en tiempo real.</span>
                          </li>

                          <li>
                            <span>Archivo debe contener la estructura creada por <strong>Nectar</strong>.</span>
                          </li>

                          <li>
                            <span>Favor verificar que sea formato xslx.</span>
                          </li>

                         

                          <li>
                            <span>Ver estructura
                              <v-icon small @click="Descargar('FormatoExcelNectar.xlsx')">download</v-icon>
                            </span>
                          </li>


                        </ul>
                        
                      </v-card>

                       <v-card max-width="500px" v-show="tipoFormatoCargar=='Generico'">
                        <v-card-subtitle><v-icon color="red">info</v-icon>Importante:</v-card-subtitle>

                        <ul class="mx-4 mb-2">

                           <li>
                            <span>Los datos seran procesados acontinuacion de ser cargados</span>
                          </li>

                           <li>
                            <span>Los archivos deberan ser ingresados en el orden de fechas ascendentes,
                               para mantener integridad de informacion.</span>
                          </li>


                          <li>
                            <span>Archivo debe contener la estructura General (Hoja1)<strong>Disatel</strong>.</span>
                          </li>

                          <li>
                            <span>Favor verificar que sea formato xslx.</span>
                          </li>

                         

                          <li>
                            <span>Ver estructura
                               <v-icon small @click="Descargar('FormatoExcelGenerico.xlsx')">download</v-icon>
                            </span>
                          </li>


                        </ul>
                        
                      </v-card>




                      <v-col cols="12" class="text--center">      
                        <v-btn color="success" dark @click="submitFiles()" :disabled="status_procesado"><v-icon dark>publish</v-icon>subir</v-btn>
                      
                       <v-btn  color="indigo" text @click="dialogFile=false" outlined class="ml-10">Cancelar</v-btn>
                      
                      </v-col>

                    </v-row>


                      <v-alert type="success" dense v-show="finalizadaCarga">Procesamiento de datos finalizado - {{this.insertados}} ingresos</v-alert>

            </v-card-text>
            <v-card-actions class="text--center">
              
            </v-card-actions>
        </v-card>
      </v-dialog>

 </v-card>

</template>

<script>


import axios from 'axios';

import { mapMutations, mapState } from "vuex";

import { mdiAccount } from '@mdi/js'


  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
                    
      

        { text: 'Vehiculo', value: 'id_comb_vehiculo.nombre' },
        { text: 'Placa', value: 'id_comb_vehiculo.placa' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Estacion', value: 'estacion' },
        { text: 'Cantidad', value: 'cantidad' },
        { text: 'Costo', value: 'costo' },
        { text: 'kmxgl', value: 'kmxgl' },
        { text: 'Eficiencia', value: 'eficiencia' },
        { text: 'observacion', value: 'observacion' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        cantidad:0,
        costo:0,
        comentario:'',
        fecha:new Date().toISOString().substr(0, 10),
        id_comb_vehiculo:'',
        id_comb_pais:'',
        estado:1,
        observacion:'',
        eficiencia:0,
        estacion:'',
        kmxgl:0
      },
      defaultItem: {
        cantidad:0,
        costo:0,
        comentario:'',
        fecha:new Date().toISOString().substr(0, 10),
        id_comb_vehiculo:'',
        id_comb_pais:'',
        estado:1,
        observacion:'',
        eficiencia:0,
        estacion:'',
        kmxgl:0
      },

       id_pais_activo:'',
       PaisesArray:[],
       ArrayIngresos:[],
       UnidadesArray:[],

       menu: false,
       dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),

          search:'',
          data_excel:[],

          column_excel:[ 
                    {label: "Vehiculo",  field: "vehiculo"},
                    {label: "Placa", field: "placa"},
                    {label: "Flota", field: "flota"},

                    {label: "Cantidad",  field: "cantidad"},
                    {label: "Costo", field: "cost0"},
                    {label: "Fecha", field: "fecha"},
                    {label: "Comentario", field: "comentario"},
                    {label: "Observacion",  field: "observacion"}
                    ],

         menu1: false,

        errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           },
       
       },

       path: mdiAccount,
       total_gl:0,
       total_dls:0,

       dialogFile:false,
       file: null,
       nombre_archivo:'',
       status_procesado:false,
       finalizadaCarga:false,

        status_edicion:true,
        status_add:false,
        status_guardar:false,
        status_btn_add:true,
        status_btn_edit:false,
        status_btn_cancelar:false,
        status_btn_update:true,

        insertados:0,

        tipoFormatoCargar:'Nectar',



    }),

    computed: {

          ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo ingreso' : 'Edicion ingreso'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArrayIngresos = [];
        this.listarPaises();
      },

          
      listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },



       listar(){

                this.ArrayIngresos=[];
                this.total_gl=0;
                this.total_dls=0;

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.post('combustibles-reporte',
                      { 

                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                      'id_pais':this.id_pais_activo
                       },
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayIngresos=response.data;


                     me.ArrayIngresos.map(function(x){

                         me.data_excel.push({ 
                              'vehiculo':x.id_comb_vehiculo.nombre,
                              'placa':x.id_comb_vehiculo.placa,
                              'flota':x.id_comb_vehiculo.flota,
                              'cantidad':x.cantidad,
                              'Costo':x.costo,
                              'fecha':me.ConvertirFecha(x.fecha),
                              'comentario':x.comentario,
                              'observacion':x.observacion
                              }); 

                              
                              me.total_gl=parseFloat(x.cantidad)+me.total_gl;
                              me.total_dls=parseFloat(x.costo)+me.total_dls;


                       });


                         me.total_gl=parseFloat(me.total_gl).toFixed(2);
                         me.total_dls=parseFloat(me.total_dls).toFixed(2);


                         //verificamos si el pais es SV el formato sera Nectar
                        
                          let StringBuscado="Salvador";
                          let textoSeleccionado="";

                          me.PaisesArray.map(function(x){
                                if(x.value==me.id_pais_activo){

                                     textoSeleccionado=x.text;
                                     if((textoSeleccionado)&&(textoSeleccionado!=null)&&(textoSeleccionado!="")){

                                          let posicionpais = textoSeleccionado.indexOf(StringBuscado);
                                        if (posicionpais !== -1){
                                            me.tipoFormatoCargar='Nectar';
                                        } else{
                                            me.tipoFormatoCargar='Generico';
                                        }

                                  }
                                }
                          });
                          

                           
                         




                      me.ocultarLoading();
                      me.listarVehiculos();
                      me.dialogInicio=false;
                  }).catch(function(error){ console.log(error); });

          },

         listarVehiculos(){

                    this.ArrayVehiculos=[];
                 
                 

                      this.mostrarLoading({titulo:'Accediendo a datos'});
                      let config={headers:{token:this.token}};
                      let me=this;
                    axios.get(`vehiculos/${this.id_pais_activo}`,
                        config
                        ).then(function(response){
                            console.log(response);
                            me.ArrayVehiculos=response.data;

                            me.ArrayVehiculos.map(function(x){
                                        me.UnidadesArray.push({'text':x.nombre, 'value':x._id});
                            });

                          me.ocultarLoading();
                        }).catch(function(error){ console.log(error); });

      },






      editItem (item) {
        this.editedIndex = this.ArrayIngresos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayIngresos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.ArrayIngresos.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {

          //Object.assign(this.ArrayIngresos[this.editedIndex], this.editedItem)
        
                   let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;
                    let me=this;

                    axios.put(`combustibles/${id}`,
                      { 

                        'cantidad':this.editedItem.cantidad,
                        'costo':this.editedItem.costo,
                        'comentario':this.editedItem.comentario,
                        'fecha':new Date(this.editedItem.fecha+"T00:00:00.000-06:00"),
                        'id_comb_vehiculo':this.editedItem.id_comb_vehiculo,
                        'id_comb_pais':this.id_pais_activo,
                        'observacion':'Actualizado por: Admin, '+'a las:'+me.getDate(new Date()),
                        'estado':this.editedItem.estado
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                         this.close()

                  }).catch(e=>{
                        console.log(e)
                  });




        } else {
         // this.ArrayIngresos.push(this.editedItem)
            //ingreso de nuevos registros


                if((this.editedItem.cantidad!='')&&(this.editedItem.cantidad!=0)&&(this.editedItem.costo!='')
                    &&(this.editedItem.costo!=0)&&(this.editedItem.comentario!='')&&(this.editedItem.id_comb_vehiculo!='')){


                   let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});

                   let me=this;
                   
                    axios.post('nuevo-combustible',
                      {  
                        'cantidad':this.editedItem.cantidad,
                        'costo':this.editedItem.costo,
                        'comentario':this.editedItem.comentario,
                        'fecha':new Date(this.editedItem.fecha+"T00:00:00.000-06:00"),
                        'id_comb_vehiculo':this.editedItem.id_comb_vehiculo,
                        'id_comb_pais':this.id_pais_activo,
                        'estado':1,
                        'observacion':'ingresado por: Admin, '+'a las:'+me.getDate(new Date())
                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                         this.close()

                  }).catch(e=>{
                        console.log(e)
                  });

             }else{
                 alert('faltan campos');
             }






        }
        
      },


      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },


     ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },

      
     submitFiles(){

        
       let me=this;

        if (this.file) {

                this.mostrarLoading({titulo:'procesando datos'});
                let formData = new FormData();

                  //enviar achivo unico
                    formData.append("file", this.file);

                  this.status_procesado=true;

                // additional data
               // let config={headers:{token:this.token}};
                formData.append("usuario", this.usuario);

                axios.post("/upload", formData)
                    .then(response => {
                        console.log("Success!");
                        console.log({ response });

                          this.status_procesado=false;
                          me.ocultarLoading();

                        if(response.data.data){
                          me.nombre_archivo=response.data.data.name;


                          if(me.tipoFormatoCargar=='Nectar'){
                               me.procesarArchivoNectar();
                             // me.procesarArchivoGenerico();
                          }else if(me.tipoFormatoCargar=='Generico'){
                               me.procesarArchivoGenerico();
                          }
                         


                        }                

                    })
                    .catch(error => {
                        console.log({ error });
                    });
          } else {
              alert("no hay archivos que subir");
          }

       },



      procesarArchivoNectar(){

                  this.status_procesado=true;
                  let config={headers:{ token:this.token}};
                  let me=this;

                  console.log(this.id_pais_activo);

                  me.mostrarLoading({titulo:'procesando datos'});
                  axios.post('procesarArchivo',{ 
                                  'id_pais':this.id_pais_activo,
                                  'file':this.nombre_archivo
                                },config
                            ).then(response=>{
                            
                                console.log(response);
                                me.ocultarLoading();


                              me.status_procesado=true;
                              me.finalizadaCarga=true;

                              let respuesta=response.data;

                              me.insertados=respuesta.length;
                              
                              
                            }).catch(e=>{ console.log(e) });     



      },



       procesarArchivoGenerico(){

         console.log('procesar generico');

                  this.status_procesado=true;
                  let config={headers:{ token:this.token}};
                  let me=this;

                  console.log(this.id_pais_activo);

                  me.mostrarLoading({titulo:'procesando datos'});
                  axios.post('procesarArchivoGenerico',{ 
                                  'id_pais':this.id_pais_activo,
                                  'file':this.nombre_archivo
                                },config
                            ).then(response=>{
                            
                                console.log(response);
                                me.ocultarLoading();


                              me.status_procesado=true;
                              me.finalizadaCarga=true;

                              let respuesta=response.data;

                              me.insertados=respuesta.length;
                              
                              
                            }).catch(e=>{ console.log(e) });     



      },

       Descargar(title){

                    let me =this;

              axios.get(`download/${title}`, {responseType:'blob'}
                    ).then(function(response){


                       // console.log(response);

                        const blob = new Blob([response.data], { type: "text/csv" });
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = title;
                        link.click();
                        URL.revokeObjectURL(link.href);                  
                        me.ocultarLoading();
                        
                    }).catch(function(error){
                        console.log(error);
                    });
           }


    




    },
  }
</script>