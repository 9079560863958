<template class="pa-0">

<v-flex class="pa-0">

                  <v-card>
                    <v-card-title>
                       Resumen por vehiculos
                      <v-divider vertical class="mx-2"></v-divider>


                       <v-flex md2 lg2 mx-4 pt-6>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                      </v-flex>

                       
                        <v-btn dark color="success" @click="dialogInicio=true">
                        <v-icon>date_range</v-icon>
                        </v-btn>
                        


                       <vue-excel-xlsx  class="mx-1" v-show="data_excel.length >0"
                        :data="data_excel"
                        :columns="column_excel"
                        :filename="'Historial_consolidado'"
                        :sheetname="'hoja1'">
                        <v-btn color="info">
                            <v-icon dark>get_app</v-icon>
                        </v-btn>
                        
                        </vue-excel-xlsx>


                        <v-spacer></v-spacer>

                  

                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headersResumenCOMBVeh"
                      :items="ItemResumenCOMBVeh"
                      :search="search"
                    >


                    <template v-slot:[`item.detalle`]="{ item }">
                                    <v-icon color="indigo" @click="AbrirDetalleVehCOMB(item)">preview</v-icon>

                              </template>
                    
                    
                    </v-data-table>
                  </v-card>


                     <v-dialog v-model="dialogoCOMBVeh" max-width="850">
                    <v-card>

                      <v-toolbar color="red">
                        <v-toolbar-title class="white--text">  Detalle Combustibles Vehiculo {{viewVeh.nombre}} - {{viewVeh.placa}}
                          
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                          <v-icon dark @click="dialogoCOMBVeh=false">close</v-icon>
                      
                      </v-toolbar>
                      

                      <v-card-text>


                        <v-card class="mt-5">
                              <v-card-title>
                                <v-text-field
                                  v-model="buscarVeh"
                                  append-icon="search"
                                  label="buscar"
                                  single-line
                                  hide-details
                                ></v-text-field>
                              </v-card-title>
                              <v-data-table
                                :headers="headersDetalleVeh"
                                :items="itemDetalleVeh"
                                :search="buscarVeh"
                              >
                              
                                  <template v-slot:[`item.fecha`]="{ item }">
                                    <span>{{ getDate(item.fecha) }}</span>
                              </template>

                              </v-data-table>
                            </v-card>
                      
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn dark color="green darken-1" @click="dialogoCOMBVeh=false">
                          cerrar
                        </v-btn>

                      </v-card-actions>
                    </v-card>
                    </v-dialog>



                    
    <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Historial por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>



   
                 
</v-flex>


</template>


<script>


import axios from 'axios';
import { mapMutations, mapState } from "vuex";

 export default {
    data: () => ({

        PaisesArray:[],
        id_pais_activo:'',
        search:'',

        headersResumenCOMBVeh:[ {text: 'Nombre', value: 'nombre' },
                                {text: 'Placa', value: 'placa' },
                                {text: 'Flota', value: 'flota' },
                                {text: 'Distancia total', value: 'distancia' },
                                {text: 'Consumo total', value: 'consumo'},
                                {text: 'Ingreso total', value: 'ingreso'},
                                {text: 'Detalle', value: 'detalle' }],


        ItemResumenCOMBVeh:[],

        dialogoCOMBVeh:false,
        viewVeh:{},


        buscarVeh:'',

        dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


         data_excel:[],

          column_excel:[ 
                        {label: "Vehiculo",  field: "nombre"},
                        {label: "Placa", field: "placa"},
                        {label: "Flota", field: "flota"},

                        {label: "distancia",  field: "distancia"},
                        {label: "consumo", field: "consumo"},
                        {label: "Fecha", field: "ingreso"},
                        ],


        headersDetalleVeh:[
                {text:'Fecha', value:'fecha'},{text:'Distancia', value:'distancia'},
                {text:'Horas movimiento', value:'horas_mov'},{text:'Consumo GL Aprox.', value:'consumo'},
                {text:'Ingreso GL', value:'ingreso'},

                ],

        itemDetalleVeh:[],


        ArrayResumenes:[],
        resumen_vehiculos:[],
        ArrayIngresos:[],


    }),

      computed: {

          ...mapState(['usuario', 'token']),
      },
      
      created () {
      this.listarPaises();
    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),


           listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },

           listar(){

             this.ArrayResumenes=[];

             this.data_excel=[];
           
               let config={headers:{ token:this.token}};
                let me=this;
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('resumenes',{ 
                                'id_pais':this.id_pais_activo,
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin 
                              },
                            config
                            ).then(response=>{
                            
                                console.log(response);
                                me.ArrayResumenes=response.data;

                                 me.resumen_vehiculos=[];

                                me.ArrayResumenes.map(function(x){
                                    
                                        let encontrado=false;
                                        me.resumen_vehiculos.map(function(y){

                                          if(x.id_vehiculo){
                                                    
                                                    if(x.id_vehiculo._id==y._id){

                                                         let consumo=0;

                                                            if(x.consumo!="N/A"){
                                                                consumo=parseFloat(x.consumo).toFixed(2);
                                                            }

                                                        y.distancia=parseFloat(y.distancia)+parseFloat(x.distancia);
                                                        y.distancia=parseFloat(y.distancia).toFixed(2);
                                                        
                                                        y.consumo=parseFloat(y.consumo)+consumo;
                                                        y.consumo=parseFloat(y.consumo).toFixed(2);

                                                        let detalle=y.detalle;
                                                        detalle.push({'fecha':x.fecha, 'distancia':parseFloat(x.distancia).toFixed(2), 
                                                                    'horas_mov':x.tiempo_mov, 'consumo': consumo, 'ingreso':0});

                                                        y.detalle=detalle;

                                                        encontrado=true;
                                                    }

                                              }
                                        });

                                        if((encontrado==false)&&(x.id_vehiculo)){

                                            let consumo=0;

                                            if(x.consumo!="N/A"){
                                                consumo=parseFloat(x.consumo).toFixed(2);
                                            }

                                            me.resumen_vehiculos.push({'_id':x.id_vehiculo._id,'nombre':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                            'flota':x.id_vehiculo.flota, 'distancia':x.distancia, 'consumo': consumo,'ingreso':0,
                                             'detalle':[{'fecha':x.fecha, 'distancia':parseFloat(x.distancia).toFixed(2), 
                                             'horas_mov':x.tiempo_mov, 'consumo': consumo, 'ingreso':0}]});
                                        }

                                });



                                  me.resumen_vehiculos.map(function(x){

                                    me.data_excel.push(x);

                                  });


                            
                                me.listarIngresos();



                                



                            }).catch(e=>{ console.log(e) });


           },



           listarIngresos(){


                 this.ArrayIngresos=[];
                this.total_gl=0;
                this.total_dls=0;

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.post('combustibles-reporte',
                      { 

                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                      'id_pais':this.id_pais_activo
                       },
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayIngresos=response.data;




  me.resumen_vehiculos.map(function(y){ 

        me.ArrayIngresos.map(function(x){

            if(x.id_comb_vehiculo._id==y._id){

                console.log('mismo vehiculo');

                                      let detalle_local=y.detalle;

                                        detalle_local.map(function(z){

                                           let fecha_res=new Date(z.fecha);
                                          let fecha_arr=new Date(x.fecha);


                                            if( (fecha_res.getDate()==fecha_arr.getDate())
                                              &&(fecha_res.getMonth()==fecha_arr.getMonth())
                                              &&(fecha_res.getFullYear()==fecha_arr.getFullYear())) {


    
                                               console.log('mismo dia:'+z.fecha+', con:'+x.fecha);
                                               console.log('contador ingreso:'+z.ingreso+', cantidad:'+x.cantidad);
                                                z.ingreso=parseFloat(z.ingreso)+parseFloat(x.cantidad);
                                                 console.log('total:'+ z.ingreso);
                                            }else{
                                                console.log('diferentes:');
                                            }//fin si la fecha es igual



                                        });

                                        y.detalle=detalle_local;


                                        y.ingreso=parseFloat(y.ingreso)+parseFloat(x.cantidad);
                                          console.log('total resumen:'+ y.ingreso);

                
                }//si es mismo vehiculo

            });


    });




                


                       me.ItemResumenCOMBVeh=me.resumen_vehiculos;


                      me.ocultarLoading();
                      me.dialogInicio=false;
                  }).catch(function(error){ console.log(error); });





           },


         AbrirDetalleVehCOMB(item){

            this.viewVeh={'nombre':item.nombre,'placa':item.placa};

            console.log(item);

            this.itemDetalleVeh=item.detalle;
            this.dialogoCOMBVeh=true;          

      },

            ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },


       getDate(evaluar) {
            let date = new Date(evaluar),
            year = date.getFullYear(),
            month = (date.getMonth() + 1).toString(),
            formatedMonth = (month.length === 1) ? ("0" + month) : month,
            day = date.getDate().toString(),
            formatedDay = (day.length === 1) ? ("0" + day) : day;
           
          return formatedDay + "-" + formatedMonth + "-" + year;
        },



    }
    
}
</script>